<template>
  <div class="edit-merchandise pb-3" style="height: 100vh">
    <!-- 標題 -->
    <div class="single-page-tag mb-3">
      <p class="sp-start sp-text fw-bolder">FB未處理留言</p>
    </div>
    <div class="px-3 row">
      <!-- 操作按鈕 -->
      <div class="col-md-6 col-12 mb-1">
        <!-- 進階搜尋 -->
        <!-- <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button> -->
        <button class="tw-btn tw-btn-secondary" @click="close">關閉</button>
      </div>
      <!-- 訊息 -->
      <!-- <div class="col-md-6 col-12 mb-1 fw-bolder">
        <p>
          <span v-if="recordAdvancedSearchData.releaseTimeRange.switch">
            上架區間:
            <span
              v-if="
                recordAdvancedSearchData.releaseTimeRange.startTime &&
                recordAdvancedSearchData.releaseTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.releaseTimeRange.startTime }}~{{
                recordAdvancedSearchData.releaseTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.releaseTimeRange.startTime &&
                !recordAdvancedSearchData.releaseTimeRange.endTime
              "
              >{{
                recordAdvancedSearchData.releaseTimeRange.startTime
              }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.releaseTimeRange.startTime &&
                recordAdvancedSearchData.releaseTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.releaseTimeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div> -->
    </div>
    <hr />
    <ProgressBar :progressData="progressData"></ProgressBar>
    <!-- 商品列表 -->
    <template v-if="merchandiseIds.length > 0">
      <div v-for="id in merchandiseIds" :key="id">
        <FbMessagesDataTable
          :merchandiseId="id"
          :fieldFilter="fieldFilter"
          :participants="participants"
          @notifyParentFromFbMessagesDataTable="
            notifyParentFromFbMessagesDataTable
          "
        ></FbMessagesDataTable>
      </div>
    </template>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 上架區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="BHFM_ReleaseTimeRange"
                v-model="advancedSearchData.releaseTimeRange.switch"
              />
              <label class="form-check-label" for="BHFM_ReleaseTimeRange"
                >上架區間</label
              >
            </div>
            <!-- 時間 -->
            <div
              class="row mb-3"
              v-if="advancedSearchData.releaseTimeRange.switch"
            >
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="BHFM_ReleaseTimeRangeStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.releaseTimeRange.startTime"
                  />
                  <label for="BHFM_ReleaseTimeRangeStartTime" class="mb-2"
                    >起始時間</label
                  >
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="BHFM_ReleaseTimeRangeEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.releaseTimeRange.endTime"
                  />
                  <label for="BHFM_ReleaseTimeRangeEndTime" class="mb-2"
                    >結束時間</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FbMessagesDataTable from '@/components/merchandise/FbMessagesDataTable.vue'
import ProgressBar from '@/components/primeVue/ProgressBar.vue'

export default {
  components: { FbMessagesDataTable, ProgressBar },
  data() {
    return {
      // modal
      advancedSearchModal: {},
      // data
      storeId: 0,
      serverToken: '',
      merchandiseIds: [],
      // 賣場所有顧客
      participants: {
        loading: false,
        data: []
      },
      // 取得商品進度條
      progressData: {
        target: 'merchandise',
        percent: 0
      },
      // 進階搜尋
      advancedSearchData: {
        releaseTimeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getAllParticipants()
    // this.computedD4TimeRange()
    // this.advancedSearch()
  },
  mounted() {
    this.createModals(['advancedSearchModal'])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-fbMessages-local')
    this.$methods.breakOff()
  },
  computed: {
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_fb.fieldFilter
    },
  },
  methods: {
    initialization() {
      if (!this.$route.query || (this.$route.query && !this.$route.query.ids)) {
        alert('請按照正常操作進入此頁')
        window.close()
        return
      }
      this.$route.query.ids.split(',').forEach(id => {
        this.merchandiseIds.push(parseInt(id))
      });
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    showModal(status, item) {
      if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場所有顧客
    getAllParticipants() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getParticipants{getUser}}'
        }
      ]
      this.participants = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.participants = {
              loading: false,
              data: JSON.parse(JSON.stringify(storeInfo.participants))
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 計算預設時間區間
    // computedD4TimeRange() {
    //   console.log('計算預設時間區間')
    //   this.advancedSearchData.releaseTimeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
    //   this.advancedSearchData.releaseTimeRange.startTime = this.$methods.moment().subtract("90", 'days').format('YYYY-MM-DD')
    // },
    // advancedSearch(closeModal) {
    //   this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    //   let query = {"columns":[{"columnName":"id","siteObjectType":16},{"columnName":"createTime","siteObjectType":16}],"distinct":true,"group":{"groups":[{"column":{"columnName":"StoreId","siteObjectType":16},"operator":{"operator":"=","type":1,"value":this.storeId}}],"operator":1},"joins":[{"leftColumn":{"columnName":"id","siteObjectType":16},"rightColumn":{"columnName":"merchandiseId","siteObjectType":37},"type":0}],"siteObjectType":16,"sqlCommandType":1}
    //   // 上架區間
    //   if (this.advancedSearchData.releaseTimeRange.switch) {
    //     if (this.advancedSearchData.releaseTimeRange.startTime) {
    //       const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.releaseTimeRange.startTime} 00:00:00`) / 1000
    //       query.group.groups.push({
    //         column: {
    //           columnName: 'releaseTime',
    //           siteObjectType: 16
    //         },
    //         operator: {
    //           operator: ">=",
    //           type: 1,
    //           value: {
    //             name: `from_unixtime(${startTime})`
    //           }
    //         }
    //       })
    //     }
    //     if (this.advancedSearchData.releaseTimeRange.endTime) {
    //       const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.releaseTimeRange.endTime} 23:59:59`) / 1000
    //       query.group.groups.push({
    //         column: {
    //           columnName: 'releaseTime',
    //           siteObjectType: 16
    //         },
    //         operator: {
    //           operator: "<=",
    //           type: 1,
    //           value: {
    //             name: `from_unixtime(${endTime})`
    //           }
    //         }
    //       })
    //     }
    //   }
    //   if (closeModal) this.advancedSearchModal.hide()
    //   this.getMerchandises(query)
    // },
    // getMerchandises(query) {
    //   const vm = this
    //   const getMerchandiseListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
    //   const header = {
    //     authorization: this.serverToken
    //   }
    //   const data = [
    //     {
    //       query: query,
    //       methods: '{}',
    //       pagination: {
    //         limit: 1
    //       }
    //     }
    //   ]
    //   this.merchandiseIds = []
    //   $.ajax({
    //     type: 'POST',
    //     async: true,
    //     url: getMerchandiseListApi,
    //     data: JSON.stringify(data),
    //     headers: header,
    //     contentType: 'application/json',
    //     success: function(res) {
    //       console.log(res)
    //       if (res.code === '200') {
    //         const merchandises = res.data[0].objects
    //         const paging = res.data[0].paging
    //         vm.sortoutMerchandises(merchandises, paging)
    //       }
    //     },
    //     error: function(err) {
    //       console.log(err.responseJSON)
    //       vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
    //       vm.$methods.switchLoading('hide')
    //     },
    //   })
    // },
    // sortoutMerchandises(merchandises, paging) {
    //   merchandises.forEach(item => { this.merchandiseIds.push(item.id) })
    //   // 處理分頁
    //   if (paging.current !== paging.total) {
    //     this.$api.getNextSiteObjects(paging.next).then(res => {
    //       const result = res.data[0].objects
    //       const paging = res.data[0].paging
    //       this.sortoutMerchandises(result, paging)
    //     }).catch(err => {
    //       console.log(err)
    //       this.SweetAlert('other', err.responseJSON.message)
    //     })
    //   }
    //   this.progressData.percent = this.$methods.handleProgressBar(paging.current, paging.total)
    //   this.$methods.switchLoading('hide')
    // },
    close() {
      window.close()
    },
    // 來自<fb 留言表格>子層的通知
    notifyParentFromFbMessagesDataTable(object) {
      console.log(object)
      if (object.reloadPrticipants) this.getAllParticipants()
    },
  },
}
</script>
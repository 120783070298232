<template>
  <div v-if="progressData.percent > 0 && progressData.percent < 100">
    <ProgressBar
      style="height: 15px"
      class="mx-3 rounded-pill"
      :value="$methods.numberToFixed(progressData.percent)"
    ></ProgressBar>
  </div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';

export default {
  components: {ProgressBar},
  props: {
    progressData: {
      type: Object,
      default: {
        target: null,
        percent: 0,
      }
    },
  },
}
</script>